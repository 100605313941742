/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { PaginatedResponse } from '@eagle/api-types';
import { RoleFunction } from '@eagle/common';
import { Dashboard } from '@eagle/core-data-types';
import {
  FeatureIcons,
  FindItemsDeferredResult,
  getListResultDescription,
  ListPage,
  ListPageQuery,
  MiddleSpinner,
  Pagination,
  PortalFeatureIcons,
  ResultsDetailCard,
  SearchResultsTableView,
  Undefinable,
  useAuthenticated,
  useBoolFlag,
  useCustomRoutes,
  useDynamicModule,
  useHasAuthorization
} from '@eagle/react-common';
import { Button, Card, Stack, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import Axios from 'axios';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHref, useNavigate } from 'react-router-dom';
import { DASHBOARD_DETAIL_FLAG } from '../dashboard-detail/dashboard-detail';

export const DashboardsList: FC = () => {
  const { axios } = useAuthenticated();
  const { t } = useTranslation(['common', 'track']);
  const { dashboards } = useCustomRoutes();
  const navigate = useNavigate();
  const href = useHref(`/${dashboards}`);
  const { module, loaded: moduleLoaded } = useDynamicModule<FeatureIcons>('feature-icons', PortalFeatureIcons.Tracking);
  const { hasAuthorization } = useHasAuthorization();
  const SYSTEM_ADMINISTRATOR_ROLE = [RoleFunction.SYSTEM_ADMINISTRATOR] as const;
  const hasManagePermissions = hasAuthorization(SYSTEM_ADMINISTRATOR_ROLE) && useBoolFlag(DASHBOARD_DETAIL_FLAG);

  const navigateToDashboards = (item: Dashboard): void => {
    navigate(`/${dashboards}/${encodeURIComponent(item._id)}`);
  };

  const findDashboards = useCallback(({ pagination }: ListPageQuery): FindItemsDeferredResult<Dashboard> => {
    const cancelToken = Axios.CancelToken.source();

    return {
      cancel: () => cancelToken.cancel(),
      promise: axios.get<PaginatedResponse<Dashboard>>('/api/v2/my/dashboards', {
        cancelToken: cancelToken.token,
        params: {
          ...pagination,
          sort: { display: 'asc' },
          filter: { type: 'dashboard' },
        },
      }).then((response) => {
        const resultDescription = getListResultDescription({ count: response.data.count, entityKey: 'common:terms.dashboard', t });

        return {
          result: {
            results: response.data.items,
            itemCount: response.data.count ?? 0,
          },
          resultDescription,
        };
      }),
    };
  }, [axios]);

  const renderDesktopContent = (isLoading: boolean, items: Dashboard[]): JSX.Element => (
    <TableBody component="div" sx={{ filter: isLoading ? 'blur(1px)' : '', opacity: isLoading ? 0.66 : 1 }}>
      {items.map((item, i) => (
        <TableRow
          key={i}
          component={Link}
          hover
          to={`/${dashboards}/${item._id}`}
          sx={{ cursor: 'pointer', height: 60, textDecoration: 'none' }}
        >
          <TableCell component="div">
            <Stack direction="row" sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography>{item.display}</Typography>
              {hasManagePermissions
                && <Button
                  onClick={(e) => {
                    navigate(`/${dashboards}/manage/${item._id}`);
                    e.preventDefault();
                  }}
                  variant="text"
                >
                  {t('track:page.dashboards.manage.action')}
                </Button>
              }
            </Stack>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );

  const renderMobileContent = (_displayOnCard: boolean, isLoading: boolean, items: Dashboard[]): JSX.Element => (
    <Stack spacing={1} sx={{ width: 1 }}>
      {items.map((item, i) => (
        <Card key={i} sx={{ filter: isLoading ? 'blur(1px)' : '', overflow: 'visible', width: 1 }}>
          <ResultsDetailCard
            content={<Typography>{item.display}</Typography>}
            href={`${href}/${item._id}`}
            sx={{ py: 1, px: 2 }}
          />
        </Card>
      ))}
    </Stack>
  );

  const renderTableContent = (
    isLoading: boolean,
    items: Undefinable<Dashboard[]>,
    matchCount: number,
    pagination: Pagination,
    setPagination: (value: Pagination) => void,
    text: Undefinable<string>,
    error?: Error,
  ): JSX.Element => (
    <SearchResultsTableView<Dashboard>
      data-testid='dashboard-list-results'
      error={error}
      isLoading={isLoading}
      items={items}
      matchCount={matchCount}
      noResultsInstructions={t('track:page.dashboards-list.no-dashboards.hint')}
      pagination={pagination}
      renderDesktopContent={renderDesktopContent}
      renderMobileContent={renderMobileContent}
      setPagination={setPagination}
      text={text}
    />
  );

  if (!moduleLoaded) return <MiddleSpinner />;

  return (
    <ListPage<Dashboard>
      data-testid='dashboards-list'
      actions={[]}
      filterFields={[]}
      icon={module?.DashboardIcon && <module.DashboardIcon />}
      navigateToEntity={navigateToDashboards}
      onQueryChanged={findDashboards}
      renderTableContent={renderTableContent}
      searchRemoved
      showFilterButton={false}
      storageKey="dashboards"
      title={t('track:page.dashboards.title')}
    />
  );
};
